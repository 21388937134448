import { Module, Runtime } from 'nva-apriltag'
import memory from 'nva-apriltag/apriltag.js.mem' // Required for webpack build process. File must be .js

const memFile = memory // Required for webpack build process. Allows .ts

const AprilTagDetect:Function = () => {
  if(!Module || !Runtime) {
    return (image:any) => {
      console.log("No detector available.")
      return []
    }
  }

  let detections:any[] = []

  let detect = Module.cwrap('detect', 'number', [
    'number', 'number', 'number', 'number'
  ]);

  let detected = Runtime.addFunction(function(
    id, 
    x1,y1,x2,y2,x3,y3,x4,y4,
    m00,m01,m02,m10,m11,m12,m20,m21,m22
  ) {
    detections.push({
      id: id,
      x1: x1, y1: y1,
      x2: x2, y2: y2,
      x3: x3, y3: y3,
      x4: x4, y4: y4,
      m: [m00,m01,m02,m10,m11,m12,m20,m21,m22]
    })
  })

  return (image:any) => {
    let buf = Module._malloc(image.data.length * image.data.BYTES_PER_ELEMENT);
    Module.HEAPU8.set(image.data, buf);
    detections = []
    detect(detected, image.width, image.height, buf);
    Module._free(buf)
    return detections
  }
}

export default AprilTagDetect