import { gql, useApolloClient } from '@apollo/client';
import { Button, Card, CardActions, CardContent, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const DEVICE_QUERY = gql`
    query GetDevice {
        device @client {
            robotId
            sessionId
        }
    }`;

const PAGE_QUERY = gql`
  query GetPage {
    pages @client {
      getStarted {
        introduction {
          step
          context
          firstRobotId
          firstSessionId
          secondRobotId
          secondSessionId
        }
      }
    }
  }`

const FirstOperatorContent = ({onNext, classes}:any) => {
  const globalState = useApolloClient()
  const [device, setDevice] = useState({robotId:'',sessionId:''})
  const [page, setPage] = useState({step: 0, context:'SIMULATE',firstRobotId:'GuestRobot',firstSessionId:'GuestSession'})
  const [disclaimerDialogOpen, setDisclaimerDialogOpen] = useState(false)
  useEffect(() => {
      globalState.query({query:DEVICE_QUERY}).then(result => {
        setDevice(result.data.device)
      })
      globalState.query({query:PAGE_QUERY}).then(result => {
        setPage(result.data.pages.getStarted.introduction)
    })
  })
  const updateGlobalState = (device: any, page:any) => {
      globalState.writeQuery({
          query: DEVICE_QUERY,
          data: {
              device: device
          }
      })
      globalState.writeQuery({
        query: PAGE_QUERY,
        data: {
          pages: {__typename: 'pages',
            getStarted: {__typename: 'getStarted',
              introduction: page
            }
          }
        }
    })
  }
  const handleRobotChange = (event:any) => {
      const update = {...device, robotId: event.target.value}
      setDevice(update)
      const pageUpdate = {...page, firstRobotId: event.target.value}
      setPage(pageUpdate)
      updateGlobalState(update, pageUpdate)
  }
  const handleSessionChange = (event:any) => {
    const update = {...device, sessionId: event.target.value}
    setDevice(update)
    const pageUpdate = {...page, firstSessionId: event.target.value}
    setPage(pageUpdate)
    updateGlobalState(update, pageUpdate)
  }
  const handleContextChange = (context:string) => {
    const pageUpdate = {...page, step: page.step + 1, context: context}
    setPage(pageUpdate)
    updateGlobalState(device, pageUpdate)
    onNext(pageUpdate)
  }

  const handleSimulatedClick = () => {
    handleContextChange('SIMULATE')
  }

  const handleDeviceClick = () => {
    setDisclaimerDialogOpen(true)
  }

  const handleRobotClick = () => {
    handleContextChange('ROBOT')
  }

  const handleDisclaimerAgreement= () => {
    setDisclaimerDialogOpen(false)
    handleContextChange('DEVICE')
  }

  return (
    <div className={classes.root}>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Setup your robot
          </Typography>
          <Typography gutterBottom variant="h4" component="h4">
            What do we mean by robot?
          </Typography>
          <Typography variant="body1" component="p">
            In any navigation problem, there needs to be a subject that is navigating.
            This could be a person, a phone, a robot or anything that has sensors.
            A person has sensors? Yes! Our eyes are like cameras. Our ears, microphones.
            NavAbility calls these subjects a <span className={classes.emphasis}>robot</span>.
            In this tutorial, our robot will be looking for their coffee.
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Give your <span className={classes.emphasis}>robot</span> a name.
          </Typography>
          <Typography variant="body1" component="p">
            You can use the box below or the device name found in the profile drawer to the right.
          </Typography>
          <CardActions>
            <div className={classes.centered}>
              <TextField className={classes.action} label="Robot" value={page.firstRobotId} onChange={handleRobotChange}/>
            </div>
          </CardActions>
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Give your <span className={classes.emphasis}>session</span> a name.
          </Typography>
          <Typography variant="body1" component="p">
            Just like humans remembering that concert or party, robots want to remember their experiences in logical groupings.
            NavAbility calls these groups a <span className={classes.emphasis}>session</span>.
          </Typography>
          <CardActions>
            <div className={classes.centered}>
              <TextField className={classes.action} label="Session" value={page.firstSessionId} onChange={handleSessionChange}/>
            </div>
          </CardActions>
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Select tutorial experience
          </Typography>
          <Typography variant="body1" component="p">
            All of this can be done with a real robot or with your cellphone. 
            Each of those options requires some additional effort to setup a physical environment to interact with.
            If you just want to get started right away, choose Simulated.
            The on device and full robot experience are coming soon.
          </Typography>
        </CardContent>
        <CardActions>
          <div className={classes.centered}>
            <Button className={classes.action} variant="contained" color="secondary" onClick={handleSimulatedClick}> Simulated </Button>
            <Button disabled={true} className={classes.action} variant="contained" color="secondary" onClick={handleDeviceClick}> Device </Button>
            <Button disabled={true} className={classes.action} variant="contained" color="secondary" onClick={handleRobotClick}> Robot </Button>
          </div>
        </CardActions>
      </Card>
      <Dialog onClose={() => setDisclaimerDialogOpen(false)} aria-labelledby="simple-dialog-title" open={disclaimerDialogOpen}>
        <DialogTitle id="simple-dialog-title">Data Privacy Disclaimer</DialogTitle>
        <Card className={classes.story}>
          <CardContent>
          <Typography>
            In some of the following steps, we will ask for permission to use your camera. Data privacy is important to us, so your camera data is safe. We don't transmit any video or camera information to our servers, just the detected AprilTags, so no need to worry about what data is leaving your phone.
          </Typography>
          </CardContent>
          <CardActions>
            <div className={classes.centered}>
              <Button className={classes.action} variant="contained" color="secondary" onClick={handleDisclaimerAgreement}> I understand </Button>
            </div>
          </CardActions>
        </Card>
      </Dialog>
      </div>
  )
}

export default FirstOperatorContent