import React, { useEffect, useState } from 'react';

import { gql, useApolloClient } from "@apollo/client";

import { AppBar, Button, MobileStepper } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { BackwardIcon, ForwardIcon } from '../icons/icons';

import ConclusionContent from './introduction/conclusion-content';
import EnvironmentContent from './introduction/environment-content';
import FirstNavigateContent from './introduction/first-navigate-content';
import FirstOperatorContent from './introduction/first-operator-content';
import TwinAnalyzeContent from './introduction/twin-analyze-content';
import WelcomeContent from './introduction/welcome-content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    right: {
        textAlign: 'right'
    },
    largeBottom: {
      marginBottom: '50px'
    },
    dot: {
      backgroundColor: "#008000"
    },
    dotActive: {
      backgroundColor: theme.palette.secondary.main
    }
  })
)

const useContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      
    },
    story: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left'
    },
    action: {
        margin: theme.spacing(1),
    },
    centered: {
      maxWidth:'640px',
      margin:'auto'
    },
    overflow: {
      overflowY: 'auto',
      height: '100px'
    },
    confusion: {
      fontStyle: 'italic',
      color: 'red'
    },
    emphasis: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  })
)

const PAGE_QUERY = gql`
  query GetPage {
      pages @client {
        getStarted {
          introduction {
            step
            context
            firstRobotId
            firstSessionId
            secondRobotId
            secondSessionId
          }
        }
      }
  }`

const IntroductionView = () => {
  const classes = useStyles()
  const contentClasses = useContentStyles()
  
  const globalState = useApolloClient()
  const [page, setPage] = useState({step:0})
  useEffect(() => {
      globalState.query({query:PAGE_QUERY}).then(result => {
        setPage(result.data.pages.getStarted.introduction)
      })
  })

  const updatePageState = (page: any) => {
    globalState.writeQuery({
      query: PAGE_QUERY,
      data: {
        pages: {__typename: 'pages',
          getStarted: {__typename: 'getStarted',
            introduction: page
          }
        }
      }
    })
  }

  const handleNext = (pageUpdate:any) => {
    const update:any = pageUpdate.step ? pageUpdate : {...page, step:page.step + 1}
    setPage(update)
    updatePageState(update)
  }

  const handleBack = () => {
    const update:any = {...page, step:page.step - 1}
    setPage(update)
    updatePageState(update)
  }

  const handleReset = () => {
    const update:any = {...page, step:0}
    setPage(update)
    updatePageState(update)
  }

  const steps = [
    <WelcomeContent onNext={handleNext} classes={contentClasses}/>,
    <FirstOperatorContent onNext={handleNext} classes={contentClasses} />,
    <EnvironmentContent onNext={handleNext} classes={contentClasses} />,
    <FirstNavigateContent onNext={handleNext} classes={contentClasses} />,
    <TwinAnalyzeContent onNext={handleNext} classes={contentClasses} />,
    // <SecondOperatorContent onNext={handleNext} classes={contentClasses} />,
    // <SecondNavigateContent onNext={handleNext} classes={contentClasses} />,
    // <SwarmAnalyzeContent onNext={handleNext} classes={contentClasses} />,
    <ConclusionContent onNext={handleReset} classes={contentClasses} />
  ]

  return (
    <>
      <div className={classes.largeBottom}>
        {steps[page.step]}
      </div>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <MobileStepper
          variant="dots"
          classes={{
            dotActive: classes.dotActive,
          }}
          steps={steps.length}
          position="static"
          activeStep={page.step}
          nextButton={
            <>
            {page.step >= steps.length - 1 && 
            <Button size="small" onClick={handleReset}>
              Reset
              {<ForwardIcon />}
            </Button>}
            {page.step < steps.length - 1 && 
            <Button size="small" onClick={handleNext}>
              Next
              {<ForwardIcon />}
            </Button>}
            </>
          }
          backButton={
            <>
            <Button size="small" onClick={handleBack} disabled={page.step === 0}>
              {<BackwardIcon />}
              Back
            </Button>
            </>
          }
        />
      </AppBar>
  </>
)}

export default IntroductionView