import { gql, useApolloClient } from '@apollo/client'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import React, { useEffect, useState } from 'react'
import AprilTagPdf from '../../../downloads/AprilTags-35h11-dense.pdf'
import Youtube from '../../common/youtube'

const IMAGES_QUERY = graphql`
  query {
    aprilTag: file(relativePath: { eq: "tag36h11_id5.png" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`

const PAGE_QUERY = gql`
  query GetPage {
      pages @client {
        getStarted {
          introduction {
            context
          }
        }
      }
  }`

const EnvironmentContent = ({onNext, classes}:any) => {
  const images = useStaticQuery(IMAGES_QUERY)

  const globalState = useApolloClient()
  const [page, setPage] = useState({step:0, context:'SIMULATE'})
  useEffect(() => {
      globalState.query({query:PAGE_QUERY}).then(result => {
        setPage(result.data.pages.getStarted.introduction)
      })
  })

  return (
    <div className={classes.root}>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Setup your environment
          </Typography>
          <Typography gutterBottom variant="h4" component="h4">
            What is an environment?
          </Typography>
          <Typography variant="body1" component="p">
            In the last section we talked about a robot which was the subject navigating.
            Now, we need to know the things the robot is observing.
            NavAbility calls that the environment.
            Using many different types of sensors robots can sense the world.
            In this tutorial, we are just going to use AprilTags to reduce the processing load.
            These special "QRCodes" embed some knowledge we can use to identify their range and orientation in space using just a camera.
            Navigation AI will still work without the AprilTags, but we would just have to implement other widely available image processing algorithms to help
            us get the information we need.
            Here is an example of one below.
            We need an environment with detectable things like walls, doors, and most importantly coffee mugs.
          </Typography>
        </CardContent>
        <CardMedia title="Example AprilTag">
            <Img style={{display: 'block',margin:'auto'}} fixed={images.aprilTag.childImageSharp.fixed} />
        </CardMedia>
      </Card>
      <Card className={classes.story}>
        {page.context === 'SIMULATE' &&
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Simulated environment
          </Typography>
          <Typography variant="body1" component="p">
            In the last section, you selected Simulate, so we are just going to stream some pre-recorded
            video into your device to emulate walking around a boardroom. If you would like to see what that looks like,
            feel free to watch the video below.
          </Typography>
        </CardContent>
        }
        {page.context !== 'SIMULATE' &&
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Real environment
          </Typography>
          <Typography variant="body1" component="p">
            Since you did not select Simulate in the last step, we are going to need to put together an environment. If you changed your mind, you can always click back and select Simulate.
            In order to put together an environment that can be sensed by our robot, you need at least four AprilTags arranged representing random things in the environment and one AprilTag attached to a coffee mug. 
            Here is a <a download href={AprilTagPdf}>handy printout</a> if you don't have one already.
            Space them out far enough so you can't see all five at once but you can see at least two most of the time. For an example, watch the sample video below.
          </Typography>
        </CardContent>
        }
        <CardMedia>
          <div className={classes.centered}>
            <Youtube embedId="cHSWoIvzW-o" title="Navigate your robot" />
          </div>
        </CardMedia>
        <CardActions>
          <div className={classes.centered}>
              <Button className={classes.action} variant="contained" color="secondary" onClick={onNext}> Ready to navigate </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default EnvironmentContent