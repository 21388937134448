import React from 'react'
import Youtube from '../../common/youtube'

import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import { DarkNegativeTutorialIcon } from '../../icons/icons'

const WelcomeContent = ({onNext, classes}:any) => {
    return (
        <div className={classes.root}>
            <Card className={classes.story}>
                <CardContent>
                    <Typography gutterBottom variant="h2" component="h2">
                        News: More tutorials!
                    </Typography>
                    <Typography gutterBottom variant="h4" component="h4">
                        Just a quick note
                    </Typography>
                    <Typography variant="body1" component="p">
                        We've recently added tutorials! 
                        Continue with this example or navigate to our new tutorials to work through some showcased examples.
                    </Typography>
                </CardContent>
                <CardActions>
                    <div className={classes.centered}>
                        <Button variant="contained" color="secondary" onClick={() => navigate('/get-started/tutorials')} 
                            startIcon={<DarkNegativeTutorialIcon fontSize="large" />}>
                            Tutorials
                        </Button>
                    </div>
                </CardActions>
            </Card>
            <Card className={classes.story}>
                <CardContent>
                    <Typography gutterBottom variant="h2" component="h2">
                        Welcome
                    </Typography>
                    <Typography gutterBottom variant="h4" component="h4">
                        NavAbility wants to make navigating the world as easy as possible.
                    </Typography>
                    <Typography variant="body1" component="p">
                        Today, we are going to walk through how NavAbility can help each of us find our morning coffee.
                        Along the way, we are going to learn the foundations of navigation AI through real world application of the technology.
                        You don't need anything to get started.
                        However, if you want the full tangible experience, you may need to print some documents and have a cellphone available.
                        If you are really adventurous, you can even complete this by installing NavAbility on a small robot.
                    </Typography>
                </CardContent>
                <CardMedia>
                    <div className={classes.centered}>
                        <Youtube embedId="8bCWfFJTfTc" title="NavAbility Introduction" />
                    </div>
                </CardMedia>
                <CardActions>
                    <div className={classes.centered}>
                        <Button className={classes.action} variant="contained" color="secondary" onClick={onNext}> Get Started </Button>
                    </div>
                </CardActions>
            </Card>
        </div>            
    )
}

export default WelcomeContent
