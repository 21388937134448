import { gql, useApolloClient } from '@apollo/client';
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'gatsby';
import { solveSession } from 'navabilitysdk';
import React, { useEffect, useState } from 'react';
import ActivityStatus from '../../activity/activity-status';
import GeometricMap2d from '../../geometric-map/geometric-map-2d';

const ACCOUNT_QUERY = gql`
  query GetAccount {
    account @client {
      userId
      isAuthenticated
    }
  }
`;

const PAGE_QUERY = gql`
  query GetPage {
    pages @client {
      getStarted {
        introduction {
          context
          firstRobotId
          firstSessionId
        }
      }
    }
  }
`;

const TwinAnalyzeContent = ({ onNext, classes }: any) => {
  const globalState = useApolloClient();
  const [account, setAccount] = useState({
    userId: 'guest@navability.io',
    name: 'Guest',
    email: 'guest@navability.io',
    isAuthenticated: false,
  });
  const [page, setPage] = useState({ context: 'SIMULATE', firstRobotId: 'GuestRobot', firstSessionId: 'GuestSession' });
  useEffect(() => {
    globalState.query({ query: ACCOUNT_QUERY }).then((result) => {
      setAccount(result.data.account);
    });
    globalState.query({ query: PAGE_QUERY }).then((result) => {
      setPage(result.data.pages.getStarted.introduction);
    });
  });

  const handleSolveClick = () => {
    let client = { userlabel: account.userId, robotLabel: page.firstRobotId, sessionLabel: page.firstSessionId };
    solveSession(globalState, client);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Analyze the data
          </Typography>
          <Typography gutterBottom variant="h4" component="h4">
            What is a factor graph?
          </Typography>
          <Typography variant="body1" component="p">
            In the last step, your <span className={classes.emphasis}>robot</span> went searching for the coffee. The
            visualization below represents the <span className={classes.emphasis}>factor graph</span> as{' '}
            <span className={classes.emphasis}>{page.firstRobotId}</span> see's it. A{' '}
            <span className={classes.emphasis}>factor graph</span> is just an internal mathematical representation of{' '}
            <span className={classes.emphasis}>{page.firstRobotId}</span>'s real environment. Since this only includes
            data provided by <span className={classes.emphasis}>{page.firstRobotId}</span>, we call this{' '}
            <span className={classes.emphasis}>{page.firstRobotId}'s</span>{' '}
            <span className={classes.emphasis}>twin</span>. Each dot represents either a{' '}
            <span className={classes.emphasis}>variable</span> or a <span className={classes.emphasis}>factor</span>.{' '}
            <span className={classes.emphasis}>Variables</span> are denoted as x1, x2, etc or april1, april2, april3,
            etc.
            <span className={classes.emphasis}>Factors</span> are denoted as x1x2f1, x2x3f1, etc. In this example, the
            `x` variables represent the position of <span className={classes.emphasis}>{page.firstRobotId}'s</span>{' '}
            whereas the `april` variables represent the april tags. We treat the `april` variables as{' '}
            <span className={classes.emphasis}>landmarks</span> which represent an object in the world that is not{' '}
            <span className={classes.emphasis}>{page.firstRobotId}</span>. In this case, our coffee mug and various
            other recognizable objects in the scene! Below the factor graph you can see the status of the data ingestion
            as well. As more things complete you will see them enter the factor graph. This shows that everything was
            uploaded and ready to go, but how do we interpret this? That is where spatial maps come in.
          </Typography>
        </CardContent>
        <CardMedia>
          <div className={classes.centered}>
            <FactorGraphView userId={account.userId} robotId={page.firstRobotId} sessionId={page.firstSessionId}></FactorGraphView>
          </div>
          <div className={[classes.centered, classes.overflow].join(' ')}>
            <ActivityStatus userId={account.userId} robotId={page.firstRobotId} sessionId={page.firstSessionId} />
          </div>
        </CardMedia>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            What is a spatial map?
          </Typography>
          <Typography variant="body1" component="p">
            Once a factor graph is assembled, we have a bunch of information from sensors, but now we need to run
            navigation AI in order to make sense of all the data. At NavAbility, we call this inference or solving of
            the graph. Solving of the graph produces new estimates about where and when our robot is based on all the
            information provided. Since we have not run a solve yet, this view will show "No data found". Click the
            solve button to begin this process in the cloud. After the solve is complete, you will be able to see a
            real-world representation of the environment The Y-axis represents the direction of travel of our robot and
            the X-axis the direction the camera is pointing. You can see the robot moves along the Y and our landmarks
            are off in the distance in the X direction. Remember that data may still be ingesting, so if you click solve
            again after more of the variables and factors are ingested, you will get a more complete result. If you want
            to explore spatial maps in greater detail, feel free to jump over to the{' '}
            <Link to="/twin/map">Twin Map</Link>. This feature of NavAbility is free but requires you to register for an
            account.
          </Typography>
        </CardContent>
        <CardMedia>
          <div className={classes.centered}>
            <GeometricMap2d
              userId={account.userId}
              robotFilter={page.firstRobotId}
              sessionFilter={page.firstSessionId}
              exact={true}
              legend={false}
            ></GeometricMap2d>
          </div>
        </CardMedia>
        <CardActions>
          <div className={classes.centered}>
            <Button className={classes.action} variant="contained" color="secondary" onClick={handleSolveClick}>
              {' '}
              Solve{' '}
            </Button>
          </div>
        </CardActions>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            What's next
          </Typography>
          <Typography variant="body1" component="p">
            We want to have a second robot go through the environment and map it as well so we can share data between
            them and help each other find their coffee! That part is coming soon!
          </Typography>
        </CardContent>
        <CardActions>
          <div className={classes.centered}>
            <Button disabled={true} className={classes.action} variant="contained" color="secondary" onClick={onNext}>
              {' '}
              Add another robot{' '}
            </Button>
            <Button className={classes.action} variant="contained" color="secondary" onClick={onNext}>
              {' '}
              Wrap up{' '}
            </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  );
};

export default TwinAnalyzeContent;
