import { Box } from '@mui/material';
import React from 'react';
import SEO from '../../components/common/seo';
import IntroductionView from '../../components/introduction/introduction-view';

const IntroductionPage = () => {
  return (
    <Box style={{ height: '95vh', overflowY: 'auto' }}>
      <Box>
        <SEO title="NavAbility Introduction" />
        <IntroductionView />
        {/*<SubscriptionUpdates />*/}
      </Box>
    </Box>
  );
};

export default IntroductionPage;
