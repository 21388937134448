import React, { useState } from 'react';
import { gql, useSubscription } from '@apollo/client'
import { Client, MutationUpdate, StatusMessage, SUBSCRIPTION_UPDATES} from 'navabilitysdk';

const latestEvents = (events:any[]) => {
    let latestEvents = events.reduce((latest:any, event:any) => {
        if(!latest[event.requestId]) {
            latest[event.requestId] = event
        } else if (new Date(event.timestamp) > new Date(latest[event.requestId])){
            latest[event.requestId] = event
        }
        return latest
    },{})
    return Object.values(latestEvents)
}

const ActivityStatus = ({userId, robotId, sessionId}:Client) => {
    const initialEvents: any[] = []
    const [events, setEvents] = useState(initialEvents)

    var client = Client(userId, robotId, sessionId);

    const appendEvent = (event:any) => {
        let newEvents = [{...event.subscriptionData.data.mutationUpdate},...events]
        setEvents(newEvents)
    }

    useSubscription<MutationUpdate>(
        gql(SUBSCRIPTION_UPDATES),
        { variables: {client},
        onSubscriptionData: appendEvent}
      );
    

    return (
        <>
            {latestEvents(events).map(event => 
                <div key={event.requestId}>
                    {event.timestamp}: {event.state} {event.action} for request '{event.requestId && event.requestId.substring(0,8)}'
                </div>
            )}
        </>
    )
}

export default ActivityStatus