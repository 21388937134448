import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from "react-webcam";
import AprilTagDataSender from './april-tag-data-sender';
import AprilTagDetect from './april-tag-detect';

const INFO_BAR_HEIGHT_PROPORTION = 0.1
const INFO_BAR_TEXT_PROPORTION = 0.75
const DETECT_DELAY = 2500
const COUNTER_RESET = 2000
const RENDER_TIME = 33
const FOUND_TIME = 2000
const UPSAMPLE_SCALE = 1
const DOWNSAMPLE_SCALE = 1 / UPSAMPLE_SCALE

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visibleCanvas: {
        width: "100%",
        height: "100%",
        maxWidth: "640px",
        visibility: "visible"
    },
    hiddenCanvas: {
        width: "0%",
        height: "0%",
        visibility: "hidden"
    }}))


const drawUpdateBar = (progress, canvas, ctx) => {
    let h = canvas.height * INFO_BAR_HEIGHT_PROPORTION
    let left = canvas.width * 0.5 * ( 1 - progress)
    let w = canvas.width * progress

    ctx.lineWidth = "0"
    ctx.fillStyle = "#9f496e"
    ctx.rect(left, 0, w, h)
    ctx.fill()
    
    ctx.font = `${h*INFO_BAR_TEXT_PROPORTION}px Montserrat`
    ctx.textAlign = "center"
    ctx.fillStyle = "#f2ebe5"
    ctx.fillText("Detecting...", canvas.width * 0.5, h * INFO_BAR_TEXT_PROPORTION)
}

const drawFoundBar = (count, canvas, ctx) => {
    let h = canvas.height * INFO_BAR_HEIGHT_PROPORTION
    let w = canvas.width

    ctx.lineWidth = "0"
    ctx.fillStyle = "#499f7a"
    ctx.rect(0, 0, w, h)
    ctx.fill()
    
    ctx.font = `${h*INFO_BAR_TEXT_PROPORTION}px Montserrat`
    ctx.textAlign = "center"
    ctx.fillStyle = "#f2ebe5"
    let text = `Found ${count} tag`
    if(count > 1) {
        text += "s"
    }
    text += "!"
    ctx.fillText(text, canvas.width * 0.5, h * INFO_BAR_TEXT_PROPORTION)
}

const drawErrorBar = (message, canvas, ctx) => {
    let h = canvas.height * INFO_BAR_HEIGHT_PROPORTION
    let w = canvas.width

    ctx.lineWidth = "0"
    ctx.fillStyle = "red"
    ctx.rect(0, 0, w, h)
    ctx.fill()
    
    ctx.font = `${h*INFO_BAR_TEXT_PROPORTION}px Montserrat`
    ctx.textAlign = "center"
    ctx.fillStyle = "#f2ebe5"
    ctx.fillText(message, canvas.width * 0.5, h * INFO_BAR_TEXT_PROPORTION)
}

const drawDetections = (tags, canvas, ctx) => {
    ctx.scale(UPSAMPLE_SCALE,UPSAMPLE_SCALE)
    ctx.strokeStyle = "#9f496e"
    for (let d of tags) {
        ctx.beginPath()
        ctx.moveTo(d.x1, d.y1)
        ctx.lineTo(d.x2, d.y2)
        ctx.lineTo(d.x3, d.y3)
        ctx.lineTo(d.x4, d.y4)
        ctx.closePath()
        ctx.stroke()
        ctx.fill()
    }
    ctx.scale(DOWNSAMPLE_SCALE,DOWNSAMPLE_SCALE)
}

const mirrorContext = (canvas, ctx) => {
    ctx.translate(canvas.width, 0)
    ctx.scale(-1, 1)
}

const drawVideo = (video, canvas, ctx) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
}

const drawMinimizedVideo = (video, canvas, ctx) => {
    const SCALE = 0.7
    let originalAlpha = ctx.globalAlpha
    ctx.globalAlpha = 0.6
    ctx.drawImage(video, 0, canvas.height * SCALE, canvas.width * (1-SCALE), canvas.height * (1-SCALE))
    ctx.globalAlpha = originalAlpha
}

const drawCanvas = (source, canvas, ctx) => {
    ctx.drawImage(source, 0, 0, canvas.width, canvas.height)
}

const aprilTagDetect = AprilTagDetect()

const AprilTagSensor = ({userId, robotId, sessionId, detect, upload, videoSource}:any) => {
    const classes = useStyles()

    const webcamRef = useRef(null)
    const simulatedWebcamRef = useRef(null)
    const cameraCanvasRef = useRef(null)
    const downsampledCanvasRef = useRef(null)
    const detectedCanvasRef = useRef(null)

    const [tagDetected, setTagDetected] = useState(false)
    const [detectedTags, setDetectedTags] = useState([])
    const [detectorError, setDetectorError] = useState(false)

    let detectionInterval:any = null
    useEffect(() => {
        draw()
        if(detect) {
            detectionInterval = setInterval(runDetection, DETECT_DELAY)
            if(videoSource) {
                const video:any = getVideo()
                if(!video.ended) {
                    video.play()
                } else {
                    video.pause()
                    video.load()
                }
            }            
        } else if (videoSource) {
            const video:any = getVideo()
            video.pause()
            video.load()
            video.currentTime = 0
        }
        return () => {
            if(detectionInterval) {
                clearInterval(detectionInterval)
                detectionInterval = null
            }
        }
    })

    function getVideo() {
        if(videoSource) {
            return simulatedWebcamRef.current
        }
        return webcamRef.current && webcamRef.current.video
    }

    let counter = 0
    function draw() {
        requestAnimationFrame(draw)
        const video:any = getVideo()
        const canvas:any = cameraCanvasRef.current
        if (video && canvas) {
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            const ctx = canvas.getContext('2d')
            if(tagDetected) {
                const detectedCanvas:any = detectedCanvasRef.current
                mirrorContext(canvas, ctx)
                drawCanvas(detectedCanvas, canvas, ctx)
                drawDetections(detectedTags, canvas, ctx)
                drawMinimizedVideo(video, canvas, ctx)
                mirrorContext(canvas, ctx)
                drawFoundBar(detectedTags.length, canvas, ctx)
            } else {
                mirrorContext(canvas, ctx)
                drawVideo(video, canvas, ctx)
                mirrorContext(canvas, ctx)
                if (detect) {
                    counter = (counter % COUNTER_RESET) + RENDER_TIME
                    drawUpdateBar(counter / COUNTER_RESET, canvas, ctx)
                }
                if (detectorError) {
                    drawErrorBar('Detector error. Please restart browser', canvas, ctx)
                }
            }
        }
    }

    function runDetection() {
        try {
            const video:any = getVideo()
            const downsampledCanvas:any = downsampledCanvasRef.current
            const detectedCanvas:any = detectedCanvasRef.current
            if(detect && !tagDetected && video && downsampledCanvas && detectedCanvas && aprilTagDetect) {
                const ctx = detectedCanvas.getContext('2d')
                detectedCanvas.width = video.videoWidth
                detectedCanvas.height = video.videoHeight
                drawVideo(video, detectedCanvas, ctx)
                const downsampledCtx = downsampledCanvas.getContext('2d')
                downsampledCanvas.width = detectedCanvas.width * DOWNSAMPLE_SCALE
                downsampledCanvas.height = detectedCanvas.height * DOWNSAMPLE_SCALE
                drawCanvas(detectedCanvas, downsampledCanvas, downsampledCtx)       
                const image = downsampledCtx.getImageData(0, 0, downsampledCanvas.width, downsampledCanvas.height)
                let tags = aprilTagDetect(image)
                if(tags.length > 0) {
                    handleDetection(tags)
                }
            }
        } catch(e) {
            setDetectorError(true)
            console.log(e)
        }
    }

    function handleDetection(tags:any) {
        setDetectedTags(tags)
        setTagDetected(true)
        setTimeout(() => {
            setTagDetected(false)
        }, FOUND_TIME)
    }

    return (
        <div>
            {!videoSource && <Webcam 
                audio={false}
                ref={webcamRef} 
                style={{
                    width: "0%",
                    height: "0%"
                }}
                videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: "environment"
                }}
            />}
            {videoSource &&
            <video ref={simulatedWebcamRef}
                style={{
                    width: "0%",
                    height: "0%"
                }}
                width="1280" height="720"
                loop={false}
                muted={true}
            >
                <source src={videoSource} type="video/mp4" />
            </video>}
            <canvas ref={cameraCanvasRef} className={classes.visibleCanvas} />
            <canvas ref={downsampledCanvasRef} className={classes.hiddenCanvas} />
            <canvas ref={detectedCanvasRef} className={classes.hiddenCanvas} />
            {upload && <AprilTagDataSender userId={userId} robotId={robotId} sessionId={sessionId} detections={detectedTags} frequency={500}/>}
        </div>
        )
}

export default AprilTagSensor