import React, { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useInterval } from '../common/hooks'
import { addVariable, Variable, VariableType, addFactor, Factor, FactorType } from 'navabilitysdk'
import { PriorPose2Data, Pose2Pose2Data, Pose2AprilTag4CornersData } from 'navabilitysdk'

const AprilTagDataSender = ({userId, robotId, sessionId, detections, odometry, frequency}:any) => {
    const globalState = useApolloClient()

    const [variableCounter, setVariableCounter] = useState(0)
    const [factorCounters, setFactorCounters] = useState<any>({})
    const [landmarksFound, setLandmarksFound] = useState<Array<Number>>([])

    useEffect(() => {
        // Create initial variable 
        let variableLabel = `x${variableCounter}`
        addVariable(globalState, getClient(), Variable(variableLabel, VariableType.POSE2))
        setVariableCounter(variableCounter + 1)

        // Create the initial prior
        let factorLabel = `${variableLabel}f1`
        let factorData = PriorPose2Data()
        addFactor(globalState, getClient(), Factor(factorLabel, FactorType.PRIORPOSE2, [variableLabel], factorData));
    },[])

    useInterval(send,frequency || 5000)

    function send() {
        for (let d of detections) {
            let landmarkLabel = `april${d.id}`
            if(!landmarksFound.includes(d.id)) {
                addVariable(globalState, getClient(), Variable(landmarkLabel, VariableType.POSE2, ["VARIABLE", "LANDMARK"]))
                setLandmarksFound(landmarksFound => [...landmarksFound, d.id])
            }
            let previousLabel = `x${variableCounter - 1}`
            let currentLabel = `x${variableCounter}`
            addVariable(globalState, getClient(), Variable(currentLabel, VariableType.POSE2))
            addAprilTagOdometry(previousLabel,currentLabel)
            addAprilTagFactor(currentLabel, landmarkLabel, d)
            setVariableCounter(variableCounter + 1)
        }
    }

    function addAprilTagOdometry(previousLabel:String, currentLabel:String) {
        let variables = [previousLabel, currentLabel]
        let factorLabel = getFactorLabel(variables)
        let odo = odometry || {
            mu: [0.0, 0.0, 0.0],
            sigma: [2.0, 2.0, 3.14159 * 0.5]
        }
        let factorData = Pose2Pose2Data(odo.mu, odo.sigma)
        addFactor(globalState, getClient(), Factor(factorLabel, FactorType.POSE2POSE2, variables, factorData))
    }

    function addAprilTagFactor(currentLabel:String, landmarkLabel:String, tag:any) {
        let variables = [currentLabel, landmarkLabel]
        let factorLabel = getFactorLabel(variables)
        let factorData = Pose2AprilTag4CornersData(tag.id,[tag.x1, tag.y1, tag.x2, tag.y2, tag.x3, tag.y3, tag.x4, tag.y4], tag.m)
        addFactor(globalState, getClient(), Factor(factorLabel, FactorType.POSE2APRILTAG4CORNERS, variables, factorData, ["FACTOR","APRILTAG"]))
    }

    function getFactorLabel(variables:String[]) {
        let factorPrefix = variables.join("");
        let factorCounter = factorCounters[factorPrefix] || 0
        factorCounter += 1
        setFactorCounters({
            ...factorCounters,
            factorLabel: factorCounter
        })
        return `${factorPrefix}f${factorCounter}`
    }

    function getClient() {
        return {
            userId: userId,
            robotId: robotId,
            sessionId: sessionId
        }
    }

    return <div></div>
}

export default AprilTagDataSender