import React from 'react'

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'

const ConclusionCard = ({onNext, classes}:any) => {
  return (
    <div className={classes.root}>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Thank you.
          </Typography>
          <Typography variant="body1" component="p">
            I hope you had fun teaching a robot about coffee.
          </Typography>
          <Typography variant="body1" component="p">
            For the latest in navigation news, check out <a className={classes.link} href="https://www.navability.io/blog-2022/" target="_blank">NavAbility Blog</a> or
            join the <a className={classes.link} href="https://github.com/JuliaRobotics/Caesar.jl" target="_blank">Caesar.jl community.</a>
          </Typography>
          <Typography variant="body1" component="p">
            If you want to see how this can work in industry, check out our <a className={classes.link} href="https://www.navability.io/applications-2022/" target="_blank">NavAbility Applications</a>.
          </Typography>
          <Typography variant="body1" component="p">
            If you want to dive in even further, make an account and start playing with the <a className={classes.link} href="https://app.navability.io/edge/download" target="_blank">NavAbility SDK</a> or <a className={classes.link} href="https://juliarobotics.org/Caesar.jl/latest/" target="_blank">Caesar.jl</a> directly.
            Don't forget to come back as this introduction will be constantly evolving and improving as well.
          </Typography>
          <Typography variant="body1" component="p">
            Want to know more? Imagining all the places you can use this or just need additional support? Reach out to us at info@navability.io.
          </Typography>
        </CardContent>
        <CardActions>
          <div className={classes.centered}>
              <Button className={classes.action} variant="contained" color="secondary" onClick={onNext}> Start Over </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default ConclusionCard