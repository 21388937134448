import { gql, useApolloClient } from '@apollo/client'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ActivityStatus from '../../activity/activity-status'
import AprilTagSensor from '../../cellphone/april-tag-sensor'

import SimulatedMp4 from "../../../videos/BobNavigatesSmall.mp4"

const ACCOUNT_QUERY = gql`
    query GetAccount {
        account @client {
            userId
            isAuthenticated
        }
    }`;

const PAGE_QUERY = gql`
  query GetPage {
      pages @client {
        getStarted {
          introduction {
            context
            firstRobotId
            firstSessionId
          }
        }
      }
  }`

const FirstNavigateContent = ({onNext, classes}:any) => {
  const globalState = useApolloClient()
  const [account, setAccount] = useState({userId:'guest@navability.io',name:'Guest',email:'guest@navability.io',isAuthenticated:false})
  const [page, setPage] = useState({context:'SIMULATE', firstRobotId:'GuestRobot', firstSessionId:'GuestSession'})
  useEffect(() => {
    globalState.query({query: ACCOUNT_QUERY}).then(result => {
      setAccount(result.data.account)
    })
    globalState.query({query:PAGE_QUERY}).then(result => {
      setPage(result.data.pages.getStarted.introduction)
    })
  })

  const [isStarted, setStarted] = useState(false)
  const toggleIsStarted = () => {
    setStarted((isStarted) => !isStarted)
  }

  return (
    <div className={classes.root}>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="h2">
            Navigate your robot
          </Typography>
          {page.context === 'SIMULATE' && <>
          <Typography gutterBottom variant="h4" component="h4">
            Simulated navigation
          </Typography>
          <Typography variant="body1" component="p">
            In this example, we are just going to simulate a robot moving around a room. No need to setup anything. Just click start. When the video gets back to the beginning, press stop.
          </Typography> </>}
          {page.context === 'DEVICE' && <>
          <Typography gutterBottom variant="h4" component="h4">
            Device navigation
          </Typography>
          <Typography variant="body1" component="p">
            As was stated before, your arms and legs will be needed to navigate your device around.
            We have also already set up an AprilTag detector in this app so when an AprilTag is detected
            it will be sent to NavAbility to be processed by our remote navigation AI.
            To begin, press the Start button and begin moving around your environment to try and find the coffee mug.
          </Typography> </>}
          {page.context === 'ROBOT' && <>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
            Robot navigation
          </Typography>
          <Typography variant="body1" component="p">
            TODO: Navigation instructions
          </Typography> </>}
        </CardContent>
        <CardMedia>
          <div className={classes.centered}>
            <AprilTagSensor userId={account.userId} robotId={page.firstRobotId} sessionId={page.firstSessionId} detect={isStarted} upload={isStarted} videoSource={page.context === 'SIMULATE' && SimulatedMp4} />
          </div>
        </CardMedia>
        <CardActions>
          <div className={classes.centered}>
              <Button className={classes.action} variant="contained" color="secondary" onClick={toggleIsStarted} disabled={isStarted}> Start </Button>
              <Button className={classes.action} variant="contained" color="secondary" onClick={toggleIsStarted} disabled={!isStarted}> Stop </Button>
          </div>
        </CardActions>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            What is all this activity?
          </Typography>
          <Typography variant="body1" component="p">
            As your <span className={classes.emphasis}> robot </span> is exploring it's environment it is sending information up into the cloud. 
            The notifications below are communciations back from the cloud on what it is receiving and updates on how it is processing the data. 
            Since NavAbility is designed to work in conjunction with a realtime device, all the processing is completed asyncronously from the processing on your <span className={classes.emphasis}> robot </span>.
          </Typography> 
        </CardContent>
        <CardMedia>
          <div className={[classes.centered,classes.overflow].join(' ')}>
            <ActivityStatus userId={account.userId} robotId={page.firstRobotId} sessionId={page.firstSessionId}/>
          </div>
        </CardMedia>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            What is a variable?
          </Typography>
          <Typography variant="body1" component="p">
            As you can see from the many popups in the lower right, every few seconds a new data point is sent and processing is happening.
            Each second, your <span className={classes.emphasis}> robot </span> is defining a <span className={classes.emphasis}>variable</span> with which he has a prior belief of.
            In this case, we are tracking the position of your <span className={classes.emphasis}> robot </span>. However, this <span className={classes.emphasis}>variable</span> could be any states of interest 
            your application needs in order to navigate, including object positions / velocities / orientations, distinct landmarks characteristics, 
            or unique calibration parameters.
          </Typography> 
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            What is a factor?
          </Typography>
          <Typography variant="body1" component="p">
            You may also notice that <span className={classes.emphasis}>factors</span> (derived from measurement data) are being sent up for processing. A factor is a relationship between any number of <span className={classes.emphasis}>variables</span>.
            In this case, we are tracking how much time has passed between <span className={classes.emphasis}>variables</span> and the relative difference in position between two consecutive <span className={classes.emphasis}>variables</span>.
            Again, just like <span className={classes.emphasis}>variables</span>, <span className={classes.emphasis}>factors</span> are built from a wide variety of statistical tools (including non-Gaussian and multimodal) that best suites your particular application needs.
          </Typography> 
        </CardContent>
      </Card>
      <Card className={classes.story}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h4">
            Why does all this variable, factor, and landmark stuff matter?
          </Typography>
          <Typography variant="body1" component="p">
            As you will see in the next section, this is our way of modeling the data so we can leverage it to make valuable inferences.
            All of this is powered by <a href="https://juliarobotics.org/Caesar.jl/latest/" target="_blank">Caesar.jl</a> for the processing
            of <span className={classes.emphasis}>variables</span> and <span className={classes.emphasis}>factors</span>.  
          </Typography> 
        </CardContent>
        <CardActions>
          <div className={classes.centered}>
              <Button className={classes.action} variant="contained" color="secondary" onClick={onNext}> Explore the data </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default FirstNavigateContent
